import { useSelector } from "react-redux";
import { getSelectedProductId } from "../navigation/navigationSlice";
import { ProductDetails, useGetProductQuery } from "../product/productApiSlice";
import SuccessCaseForm from "./SuccessCaseForm";

const SuccessCasesPage = () => {
  const selectedProductId = useSelector(getSelectedProductId);
  // Product Query state
  const {
    data: productData,
    error: errorProduct,
    isLoading: isLoadingProduct,
  } = useGetProductQuery(selectedProductId, {
    skip: !selectedProductId,
  });
  const product = productData as ProductDetails;

  if (isLoadingProduct) return <div>Loading...</div>;
  if (errorProduct) return <div>{JSON.stringify(errorProduct)}</div>;

  return (
    <div>
      <h1 className="">Success Case</h1>
      <h1 className="text-4xl font-bold tracking-tight">{product?.name}</h1>
      <div className="py-2">
        <SuccessCaseForm productId={selectedProductId}></SuccessCaseForm>
      </div>
    </div>
  );
};
export default SuccessCasesPage;
