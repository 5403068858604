import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getSelectedTeamId, selectTeam } from "../navigation/navigationSlice";
import { TeamDetails, useFindTeamsQuery } from "./teamApiSlice";

const TeamItem: React.FC<{
  team: TeamDetails;
  isSelected: boolean;
  onSelect: () => void;
}> = ({ team, isSelected, onSelect }) => (
  <li className="grid grid-cols-8">
    <div className="col-span-1">{isSelected ? "✓" : ""}</div>
    <button className="col-span-7 text-left" onClick={onSelect}>
      {team.name}
    </button>
  </li>
);

const NewTeamItem: React.FC = () => (
  <li className="grid grid-cols-8 text-red-600">
    <div className="col-span-1">+</div>
    <NavLink to="/teammutation" className="col-span-7">
      <button className="font-semibold">New Team</button>
    </NavLink>
  </li>
);

export const TeamList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const selectedTeamId = useSelector(getSelectedTeamId);

  const {
    data: teams,
    error,
    isLoading,
    isFetching,
  } = useFindTeamsQuery([], {}) as {
    data: TeamDetails[] | undefined;
    error;
    isLoading: boolean;
    isFetching: boolean;
  };

  const sortedTeams = useMemo(() => {
    return teams?.slice().sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];
  }, [teams]);

  const selectedTeam = teams?.find((t) => t.id === selectedTeamId);

  const handleSelectTeam = (teamId) => {
    dispatch(selectTeam(teamId));
    setIsOpen(!isOpen);
    navigate("/dashboard");
  };

  // Automatically select the first team when there is no selected team and there are teams.
  useEffect(() => {
    if (!selectedTeamId && sortedTeams && sortedTeams.length > 0) {
      dispatch(selectTeam(sortedTeams[0].id));
    }
  }, [sortedTeams, isLoading, isFetching, selectedTeamId, dispatch]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <div className="space-y-2">
      {sortedTeams.length || selectedTeam ? (
        <div className="-mx-2">
          <div className="flex justify-between items-center p-2 rounded-lg bg-zinc-200">
            <div className="font-semibold">{selectedTeam?.name || ""}</div>
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? "▲" : "▼"}
            </button>
          </div>
          {isOpen && (
            <ul className="border p-1 rounded-md">
              {sortedTeams.map((team) => (
                <TeamItem
                  key={team.id}
                  team={team}
                  isSelected={selectedTeamId === team.id}
                  onSelect={() => handleSelectTeam(team.id)}
                />
              ))}
              <NewTeamItem />
            </ul>
          )}
        </div>
      ) : (
        <NewTeamItem />
      )}
    </div>
  );
};
export default TeamList;
