import React from "react";
import OverviewSummary from "./Overview";

const Dashboard: React.FC = () => {
  return (
    <div>
      <OverviewSummary />
    </div>
  );
};

export default Dashboard;
