import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Session, {
  signOut,
  useSessionContext,
} from "supertokens-auth-react/recipe/session";

const HomeLink = ({ isLogged }: { isLogged: boolean }) => {
  return (
    <NavLink to={isLogged ? "/dashboard" : "/"} className="-m-1.5 p-1.5">
      <div className="flex items-center gap-3">
        <img className=" h-10 w-auto" src="/logo.png" alt="ANKOVAR" />
        <div>
          <div className="text-2xl font-bold">ANKOVAR</div>
          <div className=" text-xs font-normal">
            Build & Tell the Right Thing
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const LoginLogoutLink = ({
  className,
  isLogged,
}: {
  className: string;
  isLogged: boolean;
}) => {
  const navigate = useNavigate();

  async function onLogout() {
    await signOut();
    navigate("/");
  }

  return isLogged ? (
    <Session.SessionAuth>
      <button onClick={onLogout} className={className}>
        Log out <span aria-hidden="true">&rarr;</span>
      </button>
    </Session.SessionAuth>
  ) : (
    <NavLink to="/auth" className={className}>
      Log in <span aria-hidden="true">&rarr;</span>
    </NavLink>
  );
};

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const sessionContext = useSessionContext();

  if (sessionContext.loading === true) {
    return null;
  }

  const isLogged = sessionContext.doesSessionExist;
  const userId = sessionContext.userId;
  //const accessTokenPayload = sessionContext.accessTokenPayload

  const MobileNav = () => {
    return (
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <HomeLink isLogged={isLogged} />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                <LoginLogoutLink
                  isLogged={isLogged}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    );
  };

  return (
    <header className="">
      <nav
        className="flex items-center justify-between p-2 bg-slate-100"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <HomeLink isLogged={isLogged} />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {isLogged && (
            <div className="flex">
              <div className="text-sm font-semibold leading-6 text-green-600">
                {userId}
              </div>
              <div>&nbsp;|&nbsp;</div>
            </div>
          )}
          <LoginLogoutLink
            isLogged={isLogged}
            className="text-sm font-semibold leading-6 text-gray-900"
          />
        </div>
      </nav>
      <MobileNav />
    </header>
  );
};
export default NavBar;
