import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

import { useSessionContext } from "supertokens-auth-react/recipe/session";

const Layout = () => {
  const sessionContext = useSessionContext();

  if (sessionContext.loading === true) {
    return null;
  }

  const isLogged = sessionContext.doesSessionExist;

  return (
    <div className="h-screen">
      <div className="border-y">
        <NavBar />
      </div>
      {isLogged ? (
        <div className="flex divide-x items-stretch">
          <div className="p-4 w-1/6">
            <Sidebar />
          </div>
          <div className="p-4 w-5/6 bg-slate-100">
            <div className="divide-y">
              <Outlet />
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-screen">
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default Layout;
