import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import { navigationSlice } from "../features/navigation/navigationSlice";
import productReducer from "../features/product/productApiSlice";
import quickMessagingReducer from "../features/quickMessaging/quickMessagingApiSlice";
import teamReducer from "../features/team/teamApiSlice";

export const store = configureStore({
  reducer: {
    navigationSlice: navigationSlice.reducer,
    quickMessaging: quickMessagingReducer,
    teamReducer: teamReducer,
    product: productReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
