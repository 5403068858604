import React, { ChangeEvent, FC } from 'react'

// Define the props type
interface SliderProps {
  id: string
  min: number
  max: number
  step: number
  value: number
  onChange: (value: number) => void
}

// The FC type stands for 'Functional Component' and is generic, meaning you can supply it with the expected props
const SliderInput: FC<SliderProps> = ({ id, min, max, step, value, onChange }) => {
  // Handle slider changes
  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value)
    onChange(newValue)
  }

  return (
    <input
      id={id}
      className="bg-orange-500 w-24 h-4 rounded-full overflow-hidden outline-none focus:outline-none active:outline-none"
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleSliderChange}
    />
  )
}

export default SliderInput
