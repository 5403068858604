import React from "react";

export type FormField<T> = {
  id: keyof T;
  label: string;
  placeHolder?: string;
};

interface LabeledTextAreaProps {
  id: string;
  name: string;
  value: string;
  label: string;
  placeHolder?: string;
  onChange?: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  readOnly?: boolean;
}

const LabeledTextArea: React.FC<LabeledTextAreaProps> = ({
  id,
  name,
  value,
  label,
  placeHolder,
  onChange,
  onBlur,
  onFocus,
  readOnly = false,
}) => (
  <>
    <label htmlFor={id} className="block text-sm font-bold text-gray-700">
      {label}
    </label>
    <textarea
      id={id}
      name={name}
      value={value}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      onBlur={onBlur ? (e) => onBlur(e) : undefined}
      //onFocus={onFocus ? (e) => onFocus(e) : undefined}
      readOnly={readOnly}
      placeholder={placeHolder}
      className="h-32 mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
    />
  </>
);

export default LabeledTextArea;
