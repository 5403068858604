import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import "./App.css";
import { ComponentWrapper, PreBuiltUIList, SuperTokensConfig } from "./config";
import Dashboard from "./features/dashboard/Dashboard";
import ProductMutationPage from "./features/product/ProductMutationPage";
import QuickMessagingPage from "./features/quickMessaging/QuickMessagingPage";
import SuccessCasesPage from "./features/successcases/SuccessCasePage";
import TeamMutationPage from "./features/team/TeamMutationPage";
import Error from "./pages/Error";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import { store } from "./store";

SuperTokens.init(SuperTokensConfig);

function App() {
  return (
    <Provider store={store}>
      <SuperTokensWrapper>
        <ComponentWrapper>
          <div>
            <Router>
              <div>
                <Routes>
                  <Route path="/" element={<Layout />}>
                    {/* This shows the login UI on "/auth" route */}
                    {getSuperTokensRoutesForReactRouterDom(
                      require("react-router-dom"),
                      PreBuiltUIList
                    )}

                    <Route path="/" element={<Home />} />

                    <Route
                      path="/dashboard"
                      element={
                        <SessionAuth>
                          <Dashboard />
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="/teammutation"
                      element={
                        <SessionAuth>
                          <TeamMutationPage />
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="/productmutation"
                      element={
                        <SessionAuth>
                          <ProductMutationPage />
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="/quickmessaging"
                      element={
                        <SessionAuth>
                          <QuickMessagingPage />
                        </SessionAuth>
                      }
                    />
                    <Route
                      path="/successcases"
                      element={
                        <SessionAuth>
                          <SuccessCasesPage />
                        </SessionAuth>
                      }
                    />
                    <Route path="*" element={<Error />} />
                  </Route>
                </Routes>
              </div>
            </Router>
          </div>
        </ComponentWrapper>
      </SuperTokensWrapper>
    </Provider>
  );
}

export default App;
