import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LabeledTextArea from "../../components/LabeledTextArea";
import { Spinner } from "../../components/Spinner";
import { plausible } from "../../utils/plausible";
import { generateUUID } from "../../utils/uuid";
import { selectTeam } from "../navigation/navigationSlice";
import {
  initialTeamMutation,
  TeamDetails,
  TeamMutation,
  useCreateTeamMutation,
} from "./teamApiSlice";

const TeamMutationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [teamMutation, setTeamMutation] = useState(initialTeamMutation);

  const [createTeam, { isLoading }] = useCreateTeamMutation();

  const handleChange = (key: keyof TeamMutation) => (value: string) => {
    setTeamMutation((prevTeamMutation) => ({
      ...prevTeamMutation,
      [key]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = generateUUID();
    plausible("TeamCreated", { teamId: id });
    createTeam({ ...teamMutation, id: id })
      .unwrap()
      .then((team: TeamDetails) => dispatch(selectTeam(team.id)))
      .then(() => navigate("/dashboard"));
  };

  return (
    <div>
      <h1 className="text-4xl font-bold tracking-tight">Team</h1>
      <div className="py-2">
        <form onSubmit={handleSubmit} className="space-y-4">
          <LabeledTextArea
            key="name"
            id="name"
            name="name"
            value={teamMutation.name}
            onChange={handleChange("name")}
            label="Name"
          />

          <div className="flex items-center space-x-2">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-75"
              disabled={isLoading || teamMutation.name.length < 3}
            >
              Save
            </button>
            <div>{isLoading && <Spinner />}</div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default TeamMutationPage;
