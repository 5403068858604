import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  getSelectedProductId,
  getSelectedTeamId,
  selectProduct,
} from "../navigation/navigationSlice";
import { ProductDetails, useFindProductsQuery } from "./productApiSlice";

const ProductItem: React.FC<{
  product: ProductDetails;
  isSelected: boolean;
  onSelect: () => void;
}> = ({ product, isSelected, onSelect }) => (
  <li className="grid grid-cols-8">
    <div className="col-span-1">{isSelected ? "✓" : ""}</div>
    <button className="col-span-7 text-left" onClick={onSelect}>
      {product.name}
    </button>
  </li>
);

const NewProductItem: React.FC = () => (
  <li className="grid grid-cols-8 text-red-600">
    <div className="col-span-1">+</div>
    <NavLink to="/productmutation" className="col-span-7">
      <button className="font-semibold">New Product</button>
    </NavLink>
  </li>
);

export const ProductsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const selectedTeamId = useSelector(getSelectedTeamId);
  const selectedProductId = useSelector(getSelectedProductId);

  const {
    data: products,
    error,
    isLoading,
    isFetching,
  } = useFindProductsQuery([["teamId", selectedTeamId]], {
    skip: !selectedTeamId,
  }) as {
    data: ProductDetails[] | undefined;
    error;
    isLoading: boolean;
    isFetching: boolean;
  };

  const sortedProducts = useMemo(() => {
    return products?.slice().sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [];
  }, [products]);

  const selectedProduct = useMemo(() => {
    return products?.find((p) => p.id === selectedProductId);
  }, [products, selectedProductId]);

  const handleSelectProduct = (productId) => {
    dispatch(selectProduct(productId));
    navigate("/dashboard");
  };

  // Automatically select the first team when there is no selected team and there are teams.
  useEffect(() => {
    if (!selectedProductId && !isFetching && products && products.length > 0) {
      dispatch(selectProduct(sortedProducts[0].id));
    }
    dispatch(selectProduct(sortedProducts[0].id));
  }, [
    selectedProductId,
    isLoading,
    isFetching,
    sortedProducts,
    products,
    dispatch,
  ]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <div className="space-y-2">
      {sortedProducts.length || selectedProduct ? (
        <div className="-mx-2">
          <div className="flex justify-between items-center p-2 rounded-lg bg-zinc-200">
            <div className="font-semibold">{selectedProduct?.name || ""}</div>
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? "▲" : "▼"}
            </button>
          </div>
          {isOpen && (
            <ul className="border p-1 rounded-md">
              {sortedProducts.map((product) => (
                <ProductItem
                  key={product.id}
                  product={product}
                  isSelected={selectedProductId === product.id}
                  onSelect={() => handleSelectProduct(product.id)}
                />
              ))}
              <NewProductItem />
            </ul>
          )}
        </div>
      ) : (
        <NewProductItem />
      )}
    </div>
  );
};

export default ProductsList;
