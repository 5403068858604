import { Entity, Mutation } from "../api/abstractSlice";
import { apiSlice } from "../api/apiSlice";

type SuccessCaseSpecifics = {
  productId: string;
  temperature: number;
  blurbWordCount: number;
  inputs: Inputs;
  outputs: Outputs;
};

type SuccessCaseMutationSpecifics = {
  productId: string;
  temperature: number;
  blurbWordCount: number;
  inputs: Inputs;
};

export type Inputs = {
  whoIsTheClient: string;
  whatWasTheStruggle: string;
  whatWasTheChallenge: string;
  whyTheyAskedUs: string;
  howWeSolvedIt: string;
  whatWasTheImpact: string;
  ourKeyEnabler: string;
  whatsNext: string;
};

export type Outputs = {
  article: string;
  blurb: string;
};

export type SuccessCase = Entity<SuccessCaseSpecifics>;
export type SuccessCaseMutation = Mutation<SuccessCaseMutationSpecifics>;

export const initialState: SuccessCase = {
  id: "",
  createdBy: "",
  createdAt: 0,
  productId: "",
  temperature: 0.7,
  blurbWordCount: 25,
  inputs: {
    whoIsTheClient: "",
    whatWasTheStruggle: "",
    whatWasTheChallenge: "",
    whyTheyAskedUs: "",
    howWeSolvedIt: "",
    whatWasTheImpact: "",
    ourKeyEnabler: "",
    whatsNext: "",
  },
  outputs: {
    article: "",
    blurb: "",
  },
};

const successCaseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuickMessages: builder.query<SuccessCase, string>({
      query: (id) => `successcases/${id}`,
      providesTags: ["SuccessCase"],
    }),
    generateSuccessCase: builder.mutation<SuccessCase, SuccessCaseMutation>({
      query: (body) => ({
        url: "successcases",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SuccessCase"],
    }),
  }),
  overrideExisting: false,
});

export const { useGenerateSuccessCaseMutation } = successCaseApiSlice;
export default successCaseApiSlice.reducer;
