import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="text-center">
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        404
      </h1>
      <p className="mt-6 text-lg leading-8 text-gray-600">Page not found</p>
      <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
        Back to home <span aria-hidden="true">→</span>
      </Link>
    </div>
  );
};

export default Error;
