import { isEnglishText } from "../../utils/textUtils";

import React, { useState } from "react";
import LabeledText from "../../components/LabeledText";
import LabeledTextArea, { FormField } from "../../components/LabeledTextArea";
import SliderInput from "../../components/Slider";
import { Spinner } from "../../components/Spinner";
import { plausible } from "../../utils/plausible";
import { generateUUID } from "../../utils/uuid";
import {
  initialState,
  Inputs,
  Outputs,
  SuccessCaseMutation,
  useGenerateSuccessCaseMutation,
} from "./successCaseApiSlice";

const inputFormFields: Array<FormField<Inputs>> = [
  {
    id: "whoIsTheClient",
    label: "Who is the client",
    placeHolder:
      "Write the client\u0027s name (if public) and briefly describe what they do.",
  },
  {
    id: "whatWasTheStruggle",
    label: "What was the struggle",
    placeHolder: "Describe the problem the client had when we met.",
  },
  {
    id: "whatWasTheChallenge",
    label: "What was the challenge",
    placeHolder:
      "Describe the challenge the client posed to us to eliminate or to mitigate the struggle.",
  },
  {
    id: "whyTheyAskedUs",
    label: "Why they asked us",
    placeHolder:
      "Describe why the client decided to ask us and let uss try to solve the problem.",
  },
  {
    id: "howWeSolvedIt",
    label: "How we solved it",
    placeHolder:
      "Describe the process we used to solve the problem and help the client make progress in their job.",
  },
  {
    id: "whatWasTheImpact",
    label: "What was the impact",
    placeHolder:
      "What changed after we applied our solution? Descirbe how our solution impacted key metric related to the struggle. Example: increased conversion rate by 50%.",
  },
  {
    id: "ourKeyEnabler",
    label: "Our key enabler ",
    placeHolder:
      "Describe the unique factor that enabled us to solve the problem (like product features, performance, automation, AI).",
  },
  {
    id: "whatsNext",
    label: "What\u0027s next",
    placeHolder:
      "Describe the opportunities unlocked for the client thanks to out unique way to solve their problem.",
  },
];

const outputFormFields: Array<FormField<Outputs>> = [
  { id: "article", label: "Success Case Article" },
  { id: "blurb", label: "Blurb" },
];

interface SuccessCaseFormProps {
  productId: string;
}

const SuccessCaseForm: React.FC<SuccessCaseFormProps> = (props) => {
  const { productId } = props;
  const [inputs, setInputs] = useState(initialState.inputs);
  const [outputs, setOutputs] = useState(initialState.outputs);
  const [temperature, setTemperature] = useState<number>(
    initialState.temperature
  );
  const [blurbWordCount, setBlurbWordCount] = useState<number>(
    initialState.blurbWordCount
  );

  const [generateSuccessCase, { isLoading }] = useGenerateSuccessCaseMutation();

  const handleChange = (key: keyof Inputs) => (value: string) => {
    setInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    plausible("SCGenerated", { productId: productId });

    const successCaseMutation: SuccessCaseMutation = {
      id: generateUUID(),
      productId: productId,
      temperature: temperature,
      blurbWordCount: blurbWordCount,
      inputs: inputs,
    };

    generateSuccessCase(successCaseMutation)
      .unwrap()
      .then((successCase) => {
        setOutputs(successCase.outputs);
      });
  };

  const minLenght = 10;

  const checkIfAnyEmptyOrTooShort = () => {
    return (
      inputs.whoIsTheClient.length < minLenght ||
      inputs.whatWasTheStruggle.length < minLenght ||
      inputs.whatWasTheChallenge.length < minLenght ||
      inputs.whyTheyAskedUs.length < minLenght ||
      inputs.howWeSolvedIt.length < minLenght ||
      inputs.whatWasTheImpact.length < minLenght ||
      inputs.ourKeyEnabler.length < minLenght ||
      inputs.whatsNext.length < minLenght
    );
  };

  const checkIfAnyNonEnglish = () => {
    return (
      !isEnglishText(inputs.whoIsTheClient) ||
      !isEnglishText(inputs.whatWasTheStruggle) ||
      !isEnglishText(inputs.whatWasTheChallenge) ||
      !isEnglishText(inputs.whyTheyAskedUs) ||
      !isEnglishText(inputs.howWeSolvedIt) ||
      !isEnglishText(inputs.whatWasTheImpact) ||
      !isEnglishText(inputs.ourKeyEnabler) ||
      !isEnglishText(inputs.whatsNext)
    );
  };

  const handleSliderChange = (newValue: number) => {
    setTemperature(newValue);
  };

  const handleBlurbWordCountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBlurbWordCount(Number(e.target.value));
  };

  return (
    <div className="">
      <div className="space-y-4">
        <form
          onSubmit={handleSubmit}
          className="flex flex-wrap gap-x-4 gap-y-3"
        >
          {inputFormFields.map(({ id, label, placeHolder }) => (
            <div key={id} className="w-64">
              <LabeledTextArea
                key={id}
                id={id}
                name={id}
                value={inputs[id]}
                onChange={handleChange(id)}
                label={label}
                placeHolder={placeHolder}
              />
            </div>
          ))}
          <div className="w-full flex-row space-x-2"></div>
          {checkIfAnyEmptyOrTooShort() && (
            <div className=" w-full flex-row space-x-2 text-red-600">
              Please fill in all fields with at least {minLenght} characters.
            </div>
          )}
          {!checkIfAnyEmptyOrTooShort() && checkIfAnyNonEnglish() && (
            <div className="w-full flex-row space-x-2 text-red-600">
              Please fill in all fields with English sentences.
            </div>
          )}

          <div className="flex items-center space-x-6">
            <div className="flex flex-row items-center gap-x-2 text-sm font-normal">
              <div>Safer</div>
              <SliderInput
                id="temperature"
                min={0.2}
                max={1.4}
                step={0.2}
                value={temperature}
                onChange={handleSliderChange}
              />
              <div>More creative</div>
            </div>
            <div>|</div>
            <div className="flex items-center space-x-1 text-sm font-normal">
              <div>Blurb</div>
              <input
                type="number"
                className="w-14 px-2 py-1 text-right"
                value={blurbWordCount}
                onChange={handleBlurbWordCountChange}
              />
              <div>words</div>
            </div>
            <div>|</div>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-40"
              disabled={
                isLoading ||
                checkIfAnyEmptyOrTooShort() ||
                checkIfAnyNonEnglish()
              }
            >
              Generate
            </button>
            <div>{isLoading && <Spinner />}</div>
          </div>
        </form>
        <form className="space-y-4 text-justify">
          {outputFormFields.map(({ id, label }) => (
            <div key={id} className="flex flex-col ">
              <LabeledText
                labelClassName="text-sm font-bold text-gray-700 py-1"
                valueClassName="w-full min-h-[2rem] resize-none py-2 px-3 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                id={id}
                value={outputs[id]}
                label={label}
              />
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default SuccessCaseForm;
