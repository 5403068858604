import { Entity, Mutation } from "../api/abstractSlice";
import { apiSlice } from "../api/apiSlice";

type MessagesSpecifics = {
  productId: string;
  version: number;
  inputs: Inputs;
  outputs: Outputs;
  isAiGenerated: boolean;
  isDraft: boolean;
  isFirstVersion: boolean;
  isLastVersion: boolean;
};

type MessagesMutationSpecifics = {
  productId: string;
  version: number;
  generate: boolean;
  inputs: Inputs;
  outputs: Outputs;
};

export type Inputs = {
  whatWeAre: string;
  targetSegments: string;
  proofWeCanDeliverValue: string;
  valuePoint1: string;
  valuePoint2: string;
  valuePoint3: string;
};

export type Outputs = {
  oneLiner: string;
  oneParagraph: string;
  hundredWords: string;
};

export type Messages = Entity<MessagesSpecifics>;
export type MessagesMutation = Mutation<MessagesMutationSpecifics>;

export const initialState: Messages = {
  id: "",
  createdBy: "",
  createdAt: 0,
  version: 0,
  productId: "",
  inputs: {
    whatWeAre: "",
    targetSegments: "",
    proofWeCanDeliverValue: "",
    valuePoint1: "",
    valuePoint2: "",
    valuePoint3: "",
  },
  outputs: {
    oneLiner: "",
    oneParagraph: "",
    hundredWords: "",
  },
  isAiGenerated: false,
  isDraft: true,
  isFirstVersion: false,
  isLastVersion: false,
};

export type Version = {
  productId: string;
  createdAt?: number;
  lookupDirection?: string;
  lookupLatestVersion: boolean;
};

const quickMessagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadDraftQuickMessages: builder.query<Messages, string>({
      query: (productId) => ({
        url: "quickmessaging/versions/draft",
        method: "GET",
        params: { productId: productId },
      }),
      providesTags: ["QuickMessages"],
    }),
    saveDraftQuickMessages: builder.mutation<Messages, MessagesMutation>({
      query: (body) => ({
        url: "quickmessaging/versions/draft",
        method: "POST",
        body,
      }),
      invalidatesTags: ["QuickMessages"],
    }),

    getLatestVersionQuickMessages: builder.query<Messages, string>({
      query: (productId) => ({
        url: "quickmessaging/versions/latest",
        params: { productId: productId },
        method: "GET",
      }),
      providesTags: ["QuickMessages"],
    }),

    getVersionQuickMessages: builder.query<Messages, [number, string]>({
      query: ([version, productId]) => ({
        url: `quickmessaging/versions/${version}`,
        params: { productId: productId },
        method: "GET",
      }),
      providesTags: ["QuickMessages"],
    }),

    generateAndSaveQuickMessages: builder.mutation<Messages, MessagesMutation>({
      query: (body) => ({
        url: "quickmessaging/versions",
        method: "POST",
        body: { ...body, generate: true },
      }),
      invalidatesTags: ["QuickMessages"],
    }),
    saveQuickMessages: builder.mutation<Messages, MessagesMutation>({
      query: (body) => ({
        url: "quickmessaging/versions",
        method: "POST",
        body: { ...body, generate: false },
      }),
      invalidatesTags: ["QuickMessages"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoadDraftQuickMessagesQuery,
  useSaveDraftQuickMessagesMutation,
  useGetLatestVersionQuickMessagesQuery,
  useGetVersionQuickMessagesQuery,
  useGenerateAndSaveQuickMessagesMutation,
  useSaveQuickMessagesMutation,
} = quickMessagesApiSlice;
export default quickMessagesApiSlice.reducer;
