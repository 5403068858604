import {
  createApi,
  fetchBaseQuery,
  TagDescription,
} from "@reduxjs/toolkit/query/react";
import Session from "supertokens-auth-react/recipe/session";

export type TagDescriptionUnion =
  | TagDescription<"Product">
  | TagDescription<"Team">
  | TagDescription<"QuickMessages">;

export const fetchHeaders = async (headers: Headers) => {
  if (await Session.doesSessionExist()) {
    const userId = await Session.getUserId();
    headers.set("x-user-id", userId);
  }
  return headers;
};

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["QuickMessages", "SuccessCase", "Product", "Team"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN + "/v1/",
    //prepareHeaders: (headers) => fetchHeaders(headers),
  }),
  endpoints: () => ({}),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});
