import { apiSlice, TagDescriptionUnion } from "./apiSlice";

export type Entity<T> = T & {
  id: string;
  createdBy: string;
  createdAt: number;
};

export type Mutation<M> = M & {
  id: string;
};

export function arrayToObject(arr: Array<[string, string]>) {
  if (!arr) return {};
  return arr.reduce(
    (o: Record<string, string>, [key, value]: [string, string]) => {
      o[key] = value;
      return o;
    },
    {}
  );
}

export const createApiSlice = <T, M>(
  name: string,
  tag: TagDescriptionUnion
) => {
  const entityName = name.charAt(0).toUpperCase() + name.slice(1);
  const entityUrl = `${name}s`;

  return apiSlice.injectEndpoints({
    endpoints: (builder) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const endpointDefinition: any = {};

      endpointDefinition[`find${entityName}s`] = builder.query<
        Entity<T>,
        Array<[string, string]>
      >({
        query: (queryParams) => ({
          url: entityUrl,
          params: arrayToObject(queryParams),
        }),
        providesTags: [tag],
      });

      endpointDefinition[`get${entityName}`] = builder.query<Entity<T>, string>(
        {
          query: (id) => `${entityUrl}/${id}`,
          providesTags: [tag],
        }
      );

      endpointDefinition[`create${entityName}`] = builder.mutation<
        Entity<T>,
        Mutation<M>
      >({
        query: (body) => ({
          url: entityUrl,
          method: "POST",
          body,
        }),
        invalidatesTags: [tag],
      });

      endpointDefinition[`update${entityName}`] = builder.mutation<
        Entity<T>,
        Partial<Mutation<M>>
      >({
        query: ({ id, ...patch }) => ({
          url: `${entityUrl}/${id}`,
          method: "PATCH",
          body: patch,
        }),
        invalidatesTags: [tag],
      });

      endpointDefinition[`delete${entityName}`] = builder.mutation({
        query: (id) => ({
          url: `${entityUrl}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: [tag],
      });

      return endpointDefinition;
    },
    overrideExisting: false,
  });
};
