import { createApiSlice, Entity, Mutation } from '../api/abstractSlice'

type ProductSpecifics = {
  name: string
  teamId: string
}

export type ProductDetails = Entity<ProductSpecifics>
export type ProductMutation = Mutation<ProductSpecifics>

export const initialProduct: ProductDetails = {
  id: '',
  createdBy: '',
  createdAt: 0,
  name: '',
  teamId: '',
}

export const initialProductMutation: ProductMutation = {
  id: '',
  name: '',
  teamId: '',
}

const productApiSlice = createApiSlice<ProductSpecifics, ProductMutation>('product', 'Product')

export const {
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useFindProductsQuery,
} = productApiSlice

export default productApiSlice.reducer
