import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import {
  getSelectedProductId,
  getSelectedTeamId,
} from "../features/navigation/navigationSlice";
import ProductsList from "../features/product/ProductsList";
import TeamList from "../features/team/TeamList";

const navigationRoutes = [
  { name: "Quick Messaging", href: "/quickmessaging" },
  { name: "Success Cases", href: "/successcases" },
];

const NavigationLinks = ({ className }: { className: string }) => {
  const links = navigationRoutes.map((item) => (
    <NavLink key={item.name} to={item.href} className={className}>
      <li>{item.name}</li>
    </NavLink>
  ));
  return <ul>{links}</ul>;
};

const Sidebar = () => {
  const sessionContext = useSessionContext();
  const selectedTeamId = useSelector(getSelectedTeamId);
  const selectedProductId = useSelector(getSelectedProductId);

  if (sessionContext.loading === true) {
    return null;
  }

  const isLogged = sessionContext.doesSessionExist;

  const productList = (
    <div>
      <h1 className="text-sm">Your products</h1>
      <ProductsList />
      <div className="py-2"></div>
    </div>
  );

  const tools = (
    <div>
      <h1 className="text-sm">Tools</h1>
      <NavigationLinks className="-mx-2 block rounded-lg py-1 px-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100" />
    </div>
  );

  return (
    <div>
      {isLogged && (
        <div>
          <h1 className="text-sm">Your teams</h1>
          <TeamList />
          <div className="py-2"></div>
          {selectedTeamId && productList}
          {selectedProductId && tools}
        </div>
      )}
    </div>
  );
};
export default Sidebar;
