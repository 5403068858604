function generateShortId() {
  const array = new Uint8Array(12) // 96 bits
  crypto.getRandomValues(array)
  const uuid = btoa(String.fromCharCode.apply(null, Array.from(array)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
    .substring(0, 16)
  return uuid
}

export function generateUUID(): string {
  return generateShortId()
}
