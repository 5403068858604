import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/index";

type NavigationState = {
  teamId: string | null;
  productId: string | null;
};

const initialState: NavigationState = {
  teamId: null,
  productId: null,
};

export const navigationSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    selectTeam: (state, action: PayloadAction<string>) => {
      state.teamId = action.payload;
      state.productId = null;
    },
    selectProduct: (state, action: PayloadAction<string>) => {
      state.productId = action.payload;
    },
  },
});

export const getSelectedTeamId = (state: RootState) =>
  state.navigationSlice.teamId;
export const getSelectedProductId = (state: RootState) =>
  state.navigationSlice.productId;
export const { selectTeam, selectProduct } = navigationSlice.actions;
