import { NavLink } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import Dashboard from "../features/dashboard/Dashboard";

// Inspired by https://tailwindui.com/components/marketing/sections/heroes

const Hero = () => {
  return (
    <div className="text-center p-8">
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        Build the right thing
      </h1>
      <p className="mt-6 text-lg leading-8  text-gray-600">
        Understand the job to be done &nbsp;|&nbsp; Uncover unmet needs
        &nbsp;|&nbsp; Segment your market.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <NavLink
          to="/dashboard"
          className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
        >
          Get started
        </NavLink>
      </div>
    </div>
  );
};

const Home = () => {
  const sessionContext = useSessionContext();
  if (sessionContext.loading === true) {
    return null;
  }
  const isLogged = sessionContext.doesSessionExist;
  return <div>{!isLogged ? <Hero /> : <Dashboard />}</div>;
};
export default Home;
