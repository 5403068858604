import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getSelectedProductId,
  getSelectedTeamId,
} from "../navigation/navigationSlice";
import { ProductDetails, useGetProductQuery } from "../product/productApiSlice";
import { TeamDetails, useGetTeamQuery } from "../team/teamApiSlice";

const OverviewSummary: React.FC = () => {
  // Navigation state
  const selectedTeamId = useSelector(getSelectedTeamId);
  const selectedProductId = useSelector(getSelectedProductId);

  // Team Query state
  const {
    data: teamData,
    error: errorTeam,
    isLoading: isLoadingTeam,
  } = useGetTeamQuery(selectedTeamId, {
    skip: !selectedTeamId,
  });
  const team = teamData as TeamDetails;

  // Product Query state
  const {
    data: productData,
    error: errorProduct,
    isLoading: isLoadingProduct,
  } = useGetProductQuery(selectedProductId, {
    skip: !selectedProductId,
  });
  const product = productData as ProductDetails;

  // Loading state
  if (isLoadingProduct || isLoadingTeam) return <div>Loading...</div>;

  // Error state
  if (errorTeam) return <div>{JSON.stringify(errorTeam)}</div>;

  // Error state
  if (errorProduct) return <div>{JSON.stringify(errorProduct)}</div>;

  const newTeam = (
    <NavLink to="/teammutation" className=" text-red-600 col-span-7">
      <button>new team</button>
    </NavLink>
  );

  const newProduct = (
    <NavLink to="/productmutation" className=" text-red-600 col-span-7">
      <button>new product</button>
    </NavLink>
  );

  // Team
  let teamContent = (
    <div>You aren&apos;t in a team yet. Please crearte a {newTeam}.</div>
  );
  if (selectedTeamId && team)
    teamContent = <div>Your team is {team?.name}</div>;

  // Product
  let productContent = <div></div>;
  if (selectedTeamId) {
    productContent = (
      <div>
        You don&apos;t have any product yet. Please crearte a {newProduct}.
      </div>
    );
    if (selectedProductId && product)
      productContent = <div>You are working on {product?.name}</div>;
  }

  // Successfully fetched product
  return (
    <div>
      <div>{teamContent}</div>
      <div>{productContent}</div>
    </div>
  );
};

export default OverviewSummary;
