import Session from "supertokens-auth-react/recipe/session";

export function plausible(eventName: string, params?: object): void {
  if (
    window.location.host !== "localhost" &&
    window.location.host !== "app.ankovar.local"
  ) {
    Session.getUserId().then((userId) => {
      if (userId !== undefined) {
        //create hash of userId
        if (crypto.subtle !== undefined) {
          crypto.subtle
            .digest("SHA-256", new TextEncoder().encode(userId))
            .then((userIdHash) => {
              const userIdHexHash = Array.from(new Uint8Array(userIdHash))
                .map((b) => b.toString(16).padStart(2, "0"))
                .join("");
              params = { ...params, userId: userIdHexHash };
              window.plausible(eventName, { props: params });
            });
        } else {
          console.log("crypto.subtle is undefined");
        }
      }
    });
  }
}
