import { createApiSlice, Entity, Mutation } from '../api/abstractSlice'

type TeamSpecifics = {
  name: string
}

export type TeamDetails = Entity<TeamSpecifics>
export type TeamMutation = Mutation<TeamSpecifics>

export const initialTeam: TeamDetails = {
  id: '',
  createdBy: '',
  createdAt: 0,
  name: '',
}

export const initialTeamMutation: TeamMutation = {
  id: '',
  name: '',
}

const teamApiSlice = createApiSlice<TeamSpecifics, TeamMutation>('team', 'Team')

export const {
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useGetTeamQuery,
  useFindTeamsQuery,
} = teamApiSlice

export default teamApiSlice.reducer
