import { useSelector } from "react-redux";
import OverviewSummary from "../dashboard/Overview";
import { getSelectedProductId } from "../navigation/navigationSlice";
import { ProductDetails, useGetProductQuery } from "../product/productApiSlice";
import QuickMessagingForm from "./QuickMessagingForm";

const QuickMessagingPage = () => {
  const selectedProductId = useSelector(getSelectedProductId);
  // Product Query state
  const {
    data: productData,
    error: errorProduct,
    isLoading: isLoadingProduct,
  } = useGetProductQuery(selectedProductId, {
    skip: !selectedProductId,
  });
  const product = productData as ProductDetails;

  if (isLoadingProduct) return <div>Loading...</div>;
  if (errorProduct) return <div>{JSON.stringify(errorProduct)}</div>;
  if (!product) return <OverviewSummary />;

  return (
    <div>
      <h1>Quick Messaging</h1>
      <div>
        <QuickMessagingForm product={product}></QuickMessagingForm>
      </div>
    </div>
  );
};
export default QuickMessagingPage;
