import React from "react";

export type FormField<T> = {
  id: keyof T;
  label: string;
};

interface LabeledTextProps {
  labelClassName: string;
  valueClassName: string;
  id: string;
  value: string;
  label: string;
}
interface ClickedId {
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  id: string;
}

const LabeledText: React.FC<LabeledTextProps> = ({
  labelClassName,
  valueClassName,
  id,
  value,
  label,
}) => {
  const copyToClipboard = ({ e, id }: ClickedId) => {
    e.preventDefault();
    const divToCopy = window.document.getElementById(id);
    if (divToCopy) {
      const textToCopy = divToCopy.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          const copyButton = window.document.getElementById("copy-" + id);
          copyButton!.innerText = "Copied!";
          setTimeout(() => {
            copyButton!.innerText = "Copy";
          }, 1000);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  return (
    <div className="flex flex-col">
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      <div className="flex flex-row gap-x-2">
        <div id={id} className={valueClassName}>
          {value}
        </div>
        <button
          id={"copy-" + id}
          type="submit"
          className="h-10 w-24 text-xs justify-center py-2 px-4 border bg-orange-600 hover:bg-orange-700 text-white shadow-sm text-sm font-medium rounded-md disabled:opacity-40"
          onClick={(e) => copyToClipboard({ e, id })}
          disabled={value === ""}
        >
          Copy
        </button>
      </div>
    </div>
  );
};

export default LabeledText;
