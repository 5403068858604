import { isEnglishText } from "../../utils/textUtils";

import React, { useEffect, useState } from "react";
import LabeledText from "../../components/LabeledText";
import LabeledTextArea, { FormField } from "../../components/LabeledTextArea";
import { ProductDetails } from "../product/productApiSlice";

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Spinner } from "../../components/Spinner";
import { plausible } from "../../utils/plausible";
import { generateUUID } from "../../utils/uuid";
import {
  initialState,
  Inputs,
  Outputs,
  //useSaveQuickMessagesMutation,
  useGenerateAndSaveQuickMessagesMutation,
  useGetLatestVersionQuickMessagesQuery,
  useGetVersionQuickMessagesQuery,
  useLoadDraftQuickMessagesQuery,
  useSaveDraftQuickMessagesMutation,
} from "./quickMessagingApiSlice";

const inputFormFields: Array<FormField<Inputs>> = [
  { id: "whatWeAre", label: "What we are", placeHolder: "Type here ..." },
  {
    id: "targetSegments",
    label: "Target segments",
    placeHolder:
      "Describe who we are for. For example, tech-savvy people that love growing plants.",
  },
  {
    id: "proofWeCanDeliverValue",
    label: "Proof we can deliver value",
    placeHolder:
      "Describe the evidence that we can provide the value. For example: customer ACME saw a 40% increase in repeat purchases.",
  },
  {
    id: "valuePoint1",
    label: "Value point one",
    placeHolder:
      "What is the impact of our solution on our ideal customer? For example, grow revenue by building long-term loyalty with your customers.",
  },
  { id: "valuePoint2", label: "Value point two", placeHolder: "Type here ..." },
  {
    id: "valuePoint3",
    label: "Value point three",
    placeHolder: "Type here ...",
  },
];

const outputFormFields: Array<FormField<Outputs>> = [
  { id: "oneLiner", label: "One-liner" },
  { id: "oneParagraph", label: "One-paragraph" },
  { id: "hundredWords", label: "100-word description" },
];

interface QuickMessagingFormProps {
  product: ProductDetails;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFetchBaseQueryError(error: any): error is FetchBaseQueryError {
  return error;
}

const QuickMessagingForm: React.FC<QuickMessagingFormProps> = (props) => {
  // 1. Extract product ID
  const { product } = props;
  const productId = product.id;

  // 2. State definitions
  const [inputs, setInputs] = useState(initialState.inputs);
  const [outputs, setOutputs] = useState(initialState.outputs);
  const [contentBeforeBlur, setContentBeforeBlur] = useState([
    initialState.inputs,
    initialState.outputs,
  ]);
  const [isDraft, setIsDraft] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  // 3. Draft related logic
  const {
    data: draftData,
    isLoading: draftIsLoading,
    isFetching: draftIsFetching,
  } = useLoadDraftQuickMessagesQuery(productId);
  const draftCreateAt = draftData?.createdAt;

  // 4. Latest version related logic
  const {
    data: latestVersionData,
    error: latestVersionError,
    isLoading: latestVersionIsLoading,
    isFetching: latestVersionIsFetching,
  } = useGetLatestVersionQuickMessagesQuery(productId);
  const latestVersionNotFound =
    isFetchBaseQueryError(latestVersionError) &&
    latestVersionError.status === 404;
  const latestVersionCreateAt = latestVersionData?.createdAt;
  const nextVersion =
    latestVersionData?.version > 0 ? latestVersionData?.version + 1 : 1;

  // 5. Current version related logic
  const {
    data: currentVersionData,
    isLoading: currentVersionIsLoading,
    isFetching: currentVersionIsFetching,
  } = useGetVersionQuickMessagesQuery([currentVersion, productId], {
    skip: !currentVersion,
  });

  // 6. Mutations
  const [saveDraftQuickMessages, { error: saveDraftQuickMessagesError }] =
    useSaveDraftQuickMessagesMutation();
  const [
    generateAndSaveQuickMessages,
    { isLoading: isGenerating, error: generateAndSaveQuickMessageError },
  ] = useGenerateAndSaveQuickMessagesMutation();

  // 7. Data ready
  const isDataReady =
    !draftIsLoading &&
    !draftIsFetching &&
    !latestVersionIsLoading &&
    !latestVersionIsFetching &&
    !currentVersionIsLoading &&
    !currentVersionIsFetching;

  // 8. Set modes
  const setDraftMode = () => {
    setIsDraft(true);
    if (draftData?.inputs && draftData?.outputs) {
      setInputs(() => draftData.inputs);
      setOutputs(() => draftData.outputs);
    }
    setCurrentVersion(null);
  };

  const setLatestVersionMode = () => {
    setIsDraft(false);
    setInputs(latestVersionData.inputs);
    setOutputs(latestVersionData.outputs);
    setCurrentVersion(latestVersionData.version);
  };

  const setCurrentVersionMode = () => {
    setInputs(currentVersionData.inputs);
    setOutputs(currentVersionData.outputs);
  };

  // 9. Effect
  useEffect(() => {
    if (isDataReady) {
      if (latestVersionNotFound) {
        setDraftMode();
      } else if (isDraft === null || currentVersion === null) {
        if (draftCreateAt > latestVersionCreateAt) {
          setDraftMode();
        } else {
          setLatestVersionMode();
        }
      } else {
        if (isDraft) {
          setDraftMode();
        } else {
          if (currentVersion) {
            setCurrentVersionMode();
          } else {
            setLatestVersionMode();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDataReady,
    isDraft,
    currentVersion,
    latestVersionCreateAt,
    latestVersionNotFound,
    draftCreateAt,
  ]);

  const createMessagesMutation = (options: {
    version: number;
    generate: boolean;
  }) => ({
    id: generateUUID(),
    productId: productId,
    inputs: inputs,
    outputs: outputs,
    ...options,
  });

  const handleChangeInputs = (key: keyof Inputs) => (value: string) => {
    setIsDraft(true);
    setInputs((prevInputs) => ({ ...prevInputs, [key]: value }));
  };

  /* const handleOnFocusInputs = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
  }; */

  const handleBlurInputs = () => {
    const [prevInputs, prevOutputs] = contentBeforeBlur;
    if (prevInputs !== inputs || prevOutputs !== outputs) {
      const messagesMutation = createMessagesMutation({
        version: 0,
        generate: false,
      });
      saveDraftQuickMessages(messagesMutation).unwrap();
      setContentBeforeBlur([inputs, outputs]);
    }
  };

  const handleGenerateButton = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    plausible("QMGenerated", { productId: productId });

    const messagesMutation = createMessagesMutation({
      version: nextVersion,
      generate: true,
    });
    generateAndSaveQuickMessages(messagesMutation)
      .unwrap()
      .then((quickMessages) => {
        setOutputs(quickMessages.outputs);
      });
  };

  const minLenght = 10;

  const fieldNames = [
    "proofWeCanDeliverValue",
    "targetSegments",
    "whatWeAre",
    "valuePoint1",
  ];

  const checkIfAnyEmptyOrTooShort = () => {
    return fieldNames.some((fieldName) => inputs[fieldName].length < minLenght);
  };

  const checkIfAnyNonEnglish = () => {
    return fieldNames.some((fieldName) => !isEnglishText(inputs[fieldName]));
  };

  if (saveDraftQuickMessagesError || generateAndSaveQuickMessageError) {
    return (
      <div>
        {JSON.stringify(
          saveDraftQuickMessagesError || generateAndSaveQuickMessageError
        )}
      </div>
    );
  }

  function handlePrevVersion() {
    if (currentVersion > 1) {
      setCurrentVersion(() => currentVersion - 1);
    }
  }

  function handleNextVersion() {
    if (currentVersion < latestVersionData?.version) {
      setCurrentVersion(currentVersion + 1);
    }
  }

  function handleDiscard() {
    setCurrentVersion(latestVersionData?.version);
    setIsDraft(false);
  }

  interface DiscardProps {
    isDraft: boolean;
    canDiscard: boolean;
    handleDiscard: () => void;
  }

  const Discard: React.FC<DiscardProps> = ({
    isDraft,
    canDiscard,
    handleDiscard,
  }) =>
    isDraft && (
      <div className="flex flex-row space-x-1 p-1 rounded bg-slate-200">
        <div>draft</div>
        {canDiscard && (
          <div
            onClick={handleDiscard}
            className="cursor-pointer text-orange-600 hover:text-orange-700 focus:outline-none disabled:opacity-40"
          >
            (discard)
          </div>
        )}
      </div>
    );

  interface VersionNavigationButtonProps {
    condition: boolean;
    handleClick: () => void;
    symbol: string;
  }

  const VersionNavigationButton: React.FC<VersionNavigationButtonProps> = ({
    condition,
    handleClick,
    symbol,
  }) =>
    condition ? (
      <div
        onClick={handleClick}
        className="w-4 cursor-pointer text-orange-600 hover:text-orange-700 focus:outline-none disabled:opacity-40"
      >
        {symbol}
      </div>
    ) : (
      <div className="w-4"></div>
    );

  interface VersionWithButtonsProps {
    isDraft: boolean;
    currentVersion: number;
    latestVersion: number;
    handlePrevVersion: () => void;
    handleNextVersion: () => void;
  }

  const VersionWithButtons: React.FC<VersionWithButtonsProps> = ({
    isDraft,
    currentVersion,
    latestVersion,
    handlePrevVersion,
    handleNextVersion,
  }) => {
    if (isDraft || !currentVersion) return null;

    return (
      <div className="flex flex-row space-x-0 w-50 p-1 rounded bg-slate-200">
        <VersionNavigationButton
          condition={currentVersion > 1}
          handleClick={handlePrevVersion}
          symbol="&lt;&lt;"
        />
        <span className="px-2">{currentVersion}</span>
        <VersionNavigationButton
          condition={currentVersion < latestVersion}
          handleClick={handleNextVersion}
          symbol="&gt;&gt;"
        />
      </div>
    );
  };

  interface VersionPanelProps {
    isDraft: boolean;
    currentVersion: number;
    latestVersion: number;
    handleDiscard: () => void;
    handlePrevVersion: () => void;
    handleNextVersion: () => void;
  }

  const VersionPanel: React.FC<VersionPanelProps> = ({
    isDraft,
    currentVersion,
    latestVersion,
    handleDiscard,
    handlePrevVersion,
    handleNextVersion,
  }) => (
    <div className="flex flex-row text-xs py-2 font-mono">
      {isDraft ? (
        <Discard
          isDraft={isDraft}
          handleDiscard={handleDiscard}
          canDiscard={latestVersion > 0}
        />
      ) : (
        <VersionWithButtons
          isDraft={isDraft}
          currentVersion={currentVersion}
          latestVersion={latestVersion}
          handlePrevVersion={handlePrevVersion}
          handleNextVersion={handleNextVersion}
        />
      )}
    </div>
  );

  return (
    <div className="py-2">
      <div></div>
      <h1 className="text-4xl font-bold tracking-tight">{product?.name}</h1>

      <VersionPanel
        isDraft={isDraft}
        handleDiscard={handleDiscard}
        currentVersion={currentVersion}
        latestVersion={latestVersionData?.version}
        handlePrevVersion={handlePrevVersion}
        handleNextVersion={handleNextVersion}
      />

      <div className="py-2 space-y-4">
        <form
          onSubmit={handleGenerateButton}
          className="flex flex-wrap gap-x-4 gap-y-3"
        >
          {inputFormFields.map(({ id, label, placeHolder }) => (
            <div key={id} className="w-64">
              <LabeledTextArea
                key={id}
                id={id}
                name={id}
                value={inputs[id]}
                onChange={handleChangeInputs(id)}
                onBlur={handleBlurInputs}
                label={label}
                placeHolder={placeHolder}
              />
            </div>
          ))}
          <div className="w-full flex-row space-x-2"></div>

          {checkIfAnyEmptyOrTooShort() && (
            <div className=" w-full flex-row space-x-2 text-red-600">
              Please fill in all fields with at least {minLenght} characters.
            </div>
          )}

          {!checkIfAnyEmptyOrTooShort() && checkIfAnyNonEnglish() && (
            <div className="w-full flex-row space-x-2 text-red-600">
              Please fill in all fields with English sentences.
            </div>
          )}

          <div className="flex items-center space-x-2">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-40"
              disabled={
                isGenerating ||
                checkIfAnyEmptyOrTooShort() ||
                checkIfAnyNonEnglish()
              }
            >
              Generate
            </button>
            <div>{isGenerating && <Spinner />}</div>
            <div>
              {isGenerating && <span>This may take a short while ...</span>}
            </div>
          </div>
        </form>

        <form className="space-y-4 text-justify">
          {outputFormFields.map(({ id, label }) => (
            <div key={id} className="flex flex-col">
              <LabeledText
                labelClassName="text-sm font-bold text-gray-700 py-1"
                valueClassName="w-full min-h-[2rem] resize-none py-2 px-3 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                id={id}
                value={outputs[id]}
                label={label}
              />
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default QuickMessagingForm;
